import Beams from './assets/images/Beams.jpg'
import Logo from './assets/images/Mod.svg'

function App() {
    const year = new Date().getFullYear()

    return (
        <div className="flex items-center justify-center w-full h-full bg-white">
            <img
                src={Beams}
                alt=""
                className="absolute w-full h-full -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2"
            />
            <div
                className={`absolute inset-0 bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]`}
                style={{
                    backgroundImage: `url(https://play.tailwindcss.com/img/grid.svg)`,
                }}
            />
            <div className="relative z-10 flex flex-col items-center justify-center w-4/5 h-full pb-20">
                <img src={Logo} alt="logo" className="w-28 md:w-36" />
                <p className="mt-8 mb-8 text-xl font-bold !leading-normal text-center md:mt-12 md:mb-12 text-primaryBlack md:text-3xl lg:text-5xl">
                    Medicines at your doorstep in 10 Minutes!
                </p>
                <div className="relative inline-flex group">
                    <div className="absolute transitiona-all duration-1000 opacity-50 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] md:rounded-xl rounded-lg blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt" />
                    <button className="relative inline-flex space-x-1.5 items-center justify-center px-5 py-2.5 text-base font-bold text-white transition-all duration-200 md:text-lg md:px-8 md:py-4 bg-primaryBlack rounded-lg md:rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                        <p>💊</p>
                        <p className="mb-0.5">Coming soon</p>
                    </button>
                </div>
            </div>
            <div className="absolute z-20 flex justify-between w-full px-8 text-sm font-medium md:text-base md:px-12 md:bottom-10 bottom-6">
                <span>{`© ${year} MOD Stores`}</span>
                <div className="flex items-center space-x-4 md:space-x-6">
                    <a
                        href="https://www.linkedin.com/company/medicinesondemand/jobs/"
                        rel="noreferrer"
                        role="button"
                        target="_blank"
                        className="underline decoration-transparent hover:decoration-primaryBlack underline-offset-8"
                    >
                        Careers
                    </a>
                    <a
                        href="https://www.linkedin.com/company/medicinesondemand"
                        rel="noreferrer"
                        role="button"
                        target="_blank"
                        className="underline decoration-transparent hover:decoration-primaryBlack underline-offset-8"
                    >
                        About us
                    </a>
                </div>
            </div>
        </div>
    )
}

export default App
